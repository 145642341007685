import { graphql } from "gatsby"
import React from "react"
import useRoutePath from "../../../hooks/useRoutePath"
import useWebsite from "../../../hooks/useWebsite"
import { slugify } from "../../../utils"
import BackgroundMedia from "../../molecules/BackgroundMedia"
import HighlightBlock, { HighlightBlockLayout } from "../../molecules/tileable-blocks/HighlightBlock"
//import * as styles from "./DatoHighlightToProductFinder.module.scss"

const DatoHighlightToProductFinder = (props: Queries.DatoHighlightToProductFinderFragment) => {
  //console.log('Rendering DatoHighlightToProductFinder', props)

  const getRoutePath = useRoutePath()
  const path = getRoutePath(useWebsite().productRootRoute?.originalId)

  const linkTo = path && props.searchQuery ? path + props.searchQuery : undefined
  const layout = props.layout || "Tile"

  return (
    <HighlightBlock
      linkTo={linkTo}
      id={slugify(props.title)}
      layout={layout as HighlightBlockLayout}
      media={<BackgroundMedia {...props.media} />}
      title={props.title ?? undefined}
      titleAs="h2"
      content={!!props.text && <p>{props.text}</p>}
    />
  )
}

export default DatoHighlightToProductFinder

export const query = graphql`
  fragment DatoHighlightToProductFinder on DatoCmsHighlightToProductFinder {
    id: originalId # Korjaus daton bugiin, jos käytetään structured textissä
    text
    title
    media {
      ...DatoMedia
    }
    searchQuery
    layout
  }
`
